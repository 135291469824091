import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Term, Tracks } from '../../ENV';
import MeetPreference from '../../pages/MentorshipApplication/MeetPreference';

export default function MenteeApplicationEditor({ surveyData, handleSave }) {
  const schemaValidator = z.object({
    first_name: z.string().nonempty(`First Name is required`),
    last_name: z.string().nonempty(`Last Name is required`),
    // pitt_id: z.string(),
    email: z.string().nonempty(`Email is required`).email('Email is not valid'),
    phone: z
      .string()
      .transform((value) =>
        /^\d{10}$/.test(value)
          ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
          : value
      )
      .refine((value) => /\d{3}-\d{3}-\d{4}/.test(value), {
        message: 'Please follow the format 123-123-1234',
      })
      .optional()
      .or(z.literal('')),
    desired_mentor: z.string(),
    goal: z.string(),
    interests: z.string(),
  });

  const [newTrack, setNewTrack] = useState(
    surveyData.track ? surveyData.track : ''
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schemaValidator),
    defaultValues: {
      first_name: surveyData.first_name,
      last_name: surveyData.last_name,
      email: surveyData.email,
      phone: surveyData.phone ? surveyData.phone : '',
      desired_mentor: surveyData.desired_mentor
        ? surveyData.desired_mentor
        : '',
      goal: surveyData.goal ? surveyData.goal : '',
      interests: surveyData.interests ? surveyData.interests : '',
    },
  });
  const trackChange = (event) => {
    setNewTrack(event.target.value);
  };
  const [exptGradYear, setExptGradYear] = useState(surveyData.expect_grad_year);
  const [exptGradTerm, setExptGradTerm] = useState(surveyData.expect_grad_term);
  const [meetPreference, setMeetPreference] = useState(
    surveyData.meet_preference || ''
  );
  const currentYear = new Date().getFullYear();

  const onUpdateHandler = async (data) => {
    const updateData = {
      ...data,
      track: newTrack,
      id: surveyData.id,
      expect_grad_year: exptGradYear,
      expect_grad_term: exptGradTerm,
      meet_preference: meetPreference,
    };

    handleSave(updateData);
  };

  return (
    <Box paddingTop={2} paddingBottom={2}>
      <form onSubmit={handleSubmit(onUpdateHandler, () => {})}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              {...register('first_name')}
              label="First Name"
              error={!!errors.first_name}
              helperText={errors.first_name && errors.first_name.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              {...register('last_name')}
              label="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name && errors.last_name.message}
            />
          </Grid>
          {/*<Grid item xs={4}>*/}
          {/*  <TextField*/}
          {/*    fullWidth*/}
          {/*    label="Pitt ID"*/}
          {/*    value={surveyData.pitt_id}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={4}>
            <TextField
              {...register('email')}
              variant="filled"
              type="email"
              fullWidth
              label="Email Address"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('phone')}
              fullWidth
              label="Phone"
              error={!!errors.phone}
              helperText={
                errors.phone ? errors.phone.message : 'Example: 123-123-1234'
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="track-label">Track</InputLabel>
              <Select
                labelId="track-label"
                id="track"
                label="Track"
                value={newTrack}
                onChange={trackChange}
              >
                {Object.entries(Tracks).map(([key, value]) => (
                  <MenuItem value={key} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year']}
                label="Expect Graduate Year"
                minDate={new Date((currentYear - 5).toString() + '-01-01')}
                maxDate={new Date((currentYear + 5).toString() + '-12-31')}
                value={
                  exptGradYear
                    ? new Date(exptGradYear.toString() + '-12-31')
                    : null
                }
                onChange={(newValue) => {
                  setExptGradYear(new Date(newValue.$d).getFullYear());
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="term-label">Expect Graduate Term</InputLabel>
              <Select
                labelId="term-label"
                id="term"
                label="Expect Graduate Term"
                value={exptGradTerm}
                onChange={(e) => setExptGradTerm(e.target.value)}
              >
                {Object.entries(Term).map(([key, value]) => (
                  <MenuItem value={key} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MeetPreference
              meetPreference={meetPreference}
              setMeetPreference={setMeetPreference}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('desired_mentor')}
              label="Desired Mentor Demographics"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('goal')}
              label="Professional Goals"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('interests')}
              label="Interests or Hobbies"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Detail
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
