import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  // {
  //   title: 'Features',
  //   description: [
  //     'Cool stuff',
  //     'Random feature',
  //     'Team feature',
  //     'Developer stuff',
  //     'Another one',
  //   ],
  // },
  {
    title: 'Resources',
    description: [
      'Resource',
      // 'Resource name',
      // 'Another resource',
      // 'Final resource',
    ],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

function Copyright() {
  return (
    <Typography mt={4} variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {/*<Link color="inherit" href="https://mui.com/">*/}
      SHRS, University of Pittsburgh
      {/*</Link>{' '}*/}
      {', ' + new Date().getFullYear() + '.'}
    </Typography>
  );
}

function FooterList() {
  return (
    <footer>
      <Grid container spacing={10} justify="space-evenly">
        {footers.map((footer) => (
          <Grid item xs key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            {footer.description.map((item) => (
              <Typography key={item} variant="subtitle1" color="textSecondary">
                {item}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
    </footer>
  );
}

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        {/*<FooterList />*/}
        <Copyright sx={{ paddingTop: '10px' }} />
      </Container>
    </Box>
  );
}

export default Footer;
