import React from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import applicationServices from '../../../api/services/applicationServices';
import MentorApplicationEditor from '../../../components/ApplicationUpdate/MentorApplicationEditor';
import userServices from '../../../api/services/userServices';

export default function MentorApplicationDetail({ surveyData, history }) {
  const { state } = useLocation();
  const resetState = (newSurveyData) => {
    Object.entries(newSurveyData).forEach(([key, value]) => {
      state.applicationData[0][key] = value;
    });
    history.replace({ state });
  };
  const updateApplication = async (updateData) => {
    try {
      await applicationServices.updateMentorApplication(updateData);
      resetState(updateData);
      toast.success('Successfully updated!');
    } catch (e) {
      toast.error('Update failed: ' + e);
    }
  };

  const handleSave = async (updateData) => {
    if (
      Number.isInteger(surveyData.user_account_id) &&
      (updateData.first_name !== surveyData.first_name ||
        updateData.last_name !== surveyData.last_name)
    ) {
      try {
        await userServices.updateUser(
          { username: updateData.first_name + ' ' + updateData.last_name },
          surveyData.user_account_id
        );
        await updateApplication(updateData);
      } catch (e) {
        toast.error('Account Update failed: ' + e);
      }
    } else {
      await updateApplication(updateData);
    }
  };

  return (
    <MentorApplicationEditor surveyData={surveyData} handleSave={handleSave} />
  );
}
