import { API } from '../api';

const MentorshipService = {
  async addPair(mentorId, menteeId) {
    const data = {
      mentor_id: mentorId,
      mentee_id: menteeId,
      pair_status: 'Active',
    };
    return API.post('/mentorship-pairings', data).then((res) => res.data);
  },
  async deletePairByMentorId(mentorId) {
    return API.delete(
      `/mentorship-pairings/delete-mentor-pairs/${mentorId}`
    ).then((res) => res.data);
  },
  async deletePairByMenteeId(menteeId) {
    return API.delete(
      `/mentorship-pairings/delete-mentee-pairs/${menteeId}`
    ).then((res) => res.data);
  },

  async deletePairByPairId(pairId) {
    return API.delete(`/mentorship-pairings/${pairId}`).then((res) => res.data);
  },

  async checkDuplication(data) {
    return API.post('/mentorship-pairings/duplication', data).then(
      (res) => res.data
    );
  },

  async getPairs(data) {
    return API.post('/mentorship-pairlist', data).then((res) => res.data);
  },

  async modifyStatus(pairId, status) {
    const data = {
      id: pairId,
      pair_status: status,
    };
    return API.post('/mentorship-pairlist/modify', data).then(
      (res) => res.data
    );
  },
};

export default MentorshipService;
