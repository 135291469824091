import React from 'react';
import { Grid, Button } from '@mui/material';
import firstMeetingTool from '../../assets/MSHI Mentorship-First Meeting Tool.docx';
import meetingTool from '../../assets/MSHI Mentorship-Meeting Tool.docx';

export default function MentorMenteePortal() {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <a
          href={firstMeetingTool}
          download="MSHI Mentorship-First Meeting Tool"
          target="_blank"
          rel="noreferrer"
        >
          <Button color="primary" variant="contained">
            First Meeting Tool
          </Button>
        </a>
      </Grid>

      <Grid item>
        <a
          href={meetingTool}
          download="MSHI Mentorship-Meeting Tool"
          target="_blank"
          rel="noreferrer"
        >
          <Button color="primary" variant="contained">
            Meeting Tool
          </Button>
        </a>
      </Grid>
    </Grid>
  );
}
