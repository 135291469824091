import React from 'react';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';

const NavBarData = [
  // {
  //   authority: ['Staff'],
  //   type: 'Divider',
  // },
  {
    authority: ['Staff', 'Assistant'],
    type: 'Item',
    title: 'Mentor Application',
    path: '/mentor-list',
    icon: <AssignmentIndIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Assistant'],
    type: 'Item',
    title: 'Mentee Application',
    path: '/mentee-list',
    icon: <AssignmentIndIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Assistant'],
    type: 'Item',
    title: 'Mentorship Matching',
    path: '/mentorship-matching',
    icon: <LibraryAddIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Staff', 'Assistant'],
    type: 'Item',
    title: 'Mentorship Pair',
    path: '/mentorship-pair',
    icon: <GroupIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Mentor', 'Mentee'],
    type: 'Item',
    title: 'Contact List',
    path: '/contact-list',
    icon: <ListAltIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Mentor', 'Mentee'],
    type: 'Item',
    title: 'My profile',
    path: '/my-application',
    icon: <AccountCircleIcon />,
    cName: 'nav-text',
  },
  {
    authority: ['Mentor', 'Mentee'],
    type: 'Item',
    title: 'Tools',
    path: '/mentorship-portal',
    icon: <HomeIcon />,
    cName: 'nav-text',
  },
];

export default NavBarData;
