import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import { Input } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { isEmpty } from 'underscore';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BlockIcon from '@mui/icons-material/Block';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MainCard from '../../components/MainCard';
import ApplicationServices from '../../api/services/applicationServices';
import { generateAccount, passwordAlgorithm } from '../../utils/generalTools';
import UserServices from '../../api/services/userServices';
import { ApplicationStatus } from '../../ENV';
import PairList from './components/PairList';
import { ActionData } from '../MentorMenteeMatching/components/ActionData';
import handlePair from '../../utils/mentorshipTools';
import SendEmailConfirmation from './hooks/SendEmailConfirmation';
import Mbutton from './components/Mbutton';
import MentorApplicationDetail from './components/MentorApplicationDetail';
import MenteeApplicationDetail from './components/MenteeApplicationDetail';
import PairDeleteDialog from '../../components/Popup/PairDeleteDialog';

function MentorMenteeDetailPage() {
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const {
    applicationData: [data],
    type: listType,
  } = state;
  const [status, setStatus] = useState(data.status);
  const [didCreated, setDidCreated] = useState(!!data.user_account_id);
  const [displayPassword, setDisplayPassword] = useState(
    passwordAlgorithm(data.email)
  );

  const [menteeListData, setMenteeListData] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const refresh = async () => {
    setMenteeListData(
      await ApplicationServices.getMenteesByMentorId(data.user_account_id)
    );
  };

  // const authenticationStatus = useSelector(
  //   (state) => state.authenticationStatus
  // );
  // const fetchConfig = {
  //   headers: { Authorization: `Bearer ${authenticationStatus.jwtToken}` },
  // };

  useEffect(async () => {
    await refresh();
  }, [data.user_account_id]);

  const changePairStatus = async (updateStatus, item) => {
    await handlePair(
      updateStatus,
      data.user_account_id,
      item.user_account_id,
      item.id
    );
    await refresh();
  };

  const headConfig = [
    { field: 'first_name', headerName: 'First Name', width: 100 },
    { field: 'last_name', headerName: 'Last Name', width: 100 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 100,
      type: 'date',
      valueGetter: ({ value }) =>
        value && new Date(new Date(value).toLocaleDateString('en-US')),
    },
    { field: 'email', headerName: 'Email', width: 150 },
    // {
    //   field: 'status',
    //   type: 'boolean',
    //   headerName: 'Mentee Status',
    //   width: 150,
    // },
    { field: 'pair_status', headerName: 'Pair Status', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
      width: 200,
      getActions: (params) => {
        const item = params.row;
        return item.pair_status
          ? [
              <GridActionsCellItem
                icon={
                  <Tooltip title={ActionData.activating.name}>
                    <AddTaskIcon />
                  </Tooltip>
                }
                label={ActionData.activating.name}
                onClick={() =>
                  changePairStatus(ActionData.activating.action, item)
                }
                disabled={item.pair_status == ActionData.activating.action}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title={ActionData.deactivating.name}>
                    <BlockIcon />
                  </Tooltip>
                }
                label={ActionData.deactivating.name}
                onClick={() =>
                  changePairStatus(ActionData.deactivating.action, item)
                }
                disabled={item.pair_status == ActionData.deactivating.action}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title={ActionData.concluding.name}>
                    <StopCircleIcon />
                  </Tooltip>
                }
                label={ActionData.concluding.name}
                onClick={() =>
                  changePairStatus(ActionData.concluding.action, item)
                }
                disabled={item.pair_status == ActionData.concluding.action}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title={ActionData.removing.name}>
                    <DeleteForeverIcon />
                  </Tooltip>
                }
                label={ActionData.removing.name}
                onClick={() => {
                  setSelectedItem(item);
                  setIsDisplay(true);
                }}
                disabled={item.pair_status == ActionData.removing.action}
              />,
            ]
          : [
              <GridActionsCellItem
                icon={
                  <Tooltip title={ActionData.pairing.name}>
                    <PersonAddIcon />
                  </Tooltip>
                }
                label={ActionData.pairing.name}
                onClick={() =>
                  changePairStatus(ActionData.pairing.action, item)
                }
              />,
            ];
      },
    },
  ];

  const processApplication = async (targetStatus) => {
    // 1. Create account if status is activated and account haven't been created
    let userResponse = null;
    if (targetStatus === ApplicationStatus.ACTIVATED && !didCreated) {
      const roleId = await UserServices.getRoleId(listType);
      const accountValue = generateAccount(
        roleId,
        data.email,
        data.first_name,
        data.last_name
      );
      userResponse = await UserServices.addUser(accountValue);
      setDidCreated(true);
      setDisplayPassword(accountValue.password);
      data.password = accountValue.password; // Change location data to ensure consistency when refresh page
    }

    // 2. Update mentor/mentee application status and link with user table id if applicable
    const applicationUpdateObject = { status: targetStatus };
    if (userResponse && userResponse.id) {
      applicationUpdateObject.user_account_id = userResponse.id;
    }
    ApplicationServices.setApplication(listType, id, applicationUpdateObject);

    // 3. Update browser memorized location status data
    data.status = targetStatus; // Change location data to ensure consistency when refresh page
    history.replace({ state });
  };

  const handleChangeStatus = (event) => {
    const targetStatus = event.target.value;
    processApplication(targetStatus).then(
      (response) => {
        setStatus(targetStatus);
      },
      (error) => {
        toast.error(
          'Bad request: ' + error.response.data.message[0].messages[0].message
        );
      }
    );
  };

  // const sendEmailConfirmation = async () => {
  //   try {
  //     const [loading, error, res] = await SendEmailConfirmation(
  //       {
  //         to: data.email,
  //         subject: 'Your Account and Password',
  //         email: data.email,
  //         password: displayPassword,
  //       },
  //       fetchConfig
  //     );
  //     if (res && res.status === 200) {
  //       toast.success('Email sent successful!');
  //     } else {
  //       toast.error(
  //         `Password reset failed: ${result.message || 'Unknown error'}`
  //       );
  //     }
  //   } catch (error) {
  //     toast.error(`An error occurred: ${error.toString()}`);
  //   }
  // };

  // @TODO Confirm Modal pop out
  return (
    <Box>
      <Grid container>
        {/* Title */}
        <Grid item xs={12}>
          <Typography variant="h5">Application Detail Page</Typography>
        </Grid>

        {/*Info Card */}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  {data.first_name + ' ' + data.last_name}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {listType === 'mentor' ? (
                  <MentorApplicationDetail
                    surveyData={data}
                    history={history}
                  />
                ) : (
                  <MenteeApplicationDetail
                    surveyData={data}
                    history={history}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2} paddingTop={2}>
                  <Typography variant="h6" hidden={didCreated}>
                    Please select ACTIVATED to activate this application,
                    username and account will automatically be generated
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexWrap="nowrap"
                    justifyContent="space-between"
                  >
                    <Mbutton
                      statusName="PENDING"
                      statusValue={ApplicationStatus.PENDING}
                      sourceValue={status}
                      onClick={handleChangeStatus}
                    />
                    <Mbutton
                      statusName="ACTIVATED"
                      statusValue={ApplicationStatus.ACTIVATED}
                      sourceValue={status}
                      onClick={handleChangeStatus}
                    />
                    <Mbutton
                      statusName="DISCONTINUE"
                      statusValue={ApplicationStatus.DISCONTINUE}
                      sourceValue={status}
                      onClick={handleChangeStatus}
                    />
                  </Stack>
                  <Stack spacing={2} hidden={!didCreated}>
                    <Typography variant="h6">Login Credential</Typography>
                    <Typography variant="subtitle2" sx={{ mt: 0 }}>
                      Initial password may not be current password for this
                      account if user changed their password.
                    </Typography>
                  </Stack>
                  <Stack spacing={2} hidden={!didCreated}>
                    <Typography variant="h6">Login Email</Typography>
                    <Input value={data.email} readOnly />
                    <Typography variant="h6">Initial Password</Typography>
                    <Input value={displayPassword} readOnly />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={process.env.REACT_APP_API_URL + '/admin'}
                    >
                      Reset Password
                    </a>
                    {/*<Button*/}
                    {/*  color="primary"*/}
                    {/*  block*/}
                    {/*  onClick={sendEmailConfirmation}*/}
                    {/*>*/}
                    {/*  Send Email Confirmation*/}
                    {/*</Button>*/}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        {/* List of Mentee*/}
        {listType === 'mentor' && didCreated ? (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MainCard title="Mentee List">
              {!isEmpty(menteeListData) ? (
                <PairList
                  rows={menteeListData}
                  columns={headConfig}
                  getRowId={(row) => row.mentee_id}
                />
              ) : (
                <></>
              )}
            </MainCard>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <PairDeleteDialog
        isDisplay={isDisplay}
        setIsDisplay={setIsDisplay}
        changePairStatus={changePairStatus}
        selectedItem={selectedItem}
      />
    </Box>
  );
}

export default MentorMenteeDetailPage;
