import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionData } from '../../pages/MentorMenteeMatching/components/ActionData';

export default function PairDeleteDialog({
  isDisplay,
  setIsDisplay,
  changePairStatus,
  selectedItem,
}) {
  const handleClose = () => {
    setIsDisplay(false);
  };

  const handleDelete = async () => {
    changePairStatus(ActionData.removing.action, selectedItem);
    setIsDisplay(false);
  };

  return (
    <>
      <Dialog
        open={isDisplay}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete the pair will also{' '}
            <strong style={{ color: 'red' }}>delete their communication</strong>
            . Still want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Discard</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
