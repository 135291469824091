import React from 'react';
import { Box } from '@mui/material';
import Header from './components/Header';
import MainFeaturedPost from './components/MainFeaturedPost';
import Footer from './components/Footer';
import Section from './components/Section';
import TimelineTable from './components/TimelineTable';
import mentorship1 from '../../assets/mentorship1.jpg';
import mentorship2 from '../../assets/mentorship2.jpg';
import mentorship3 from '../../assets/mentorship3.jpg';
import mentorship4 from '../../assets/mentorship4.jpg';
import ApplicationButtons from './components/ApplicationButtons';
import { Mobile } from '../../layout/BreakPoint';

export default function MentorshipProgram() {
  const mainFeaturedPost = {
    title: 'HI MENTORSHIP PROGRAM',
    description:
      'To support the rising demand for qualified Health Informatics (HI) Professionals, ' +
      'the HI faculty sees value in the development of a mentorship program that will match ' +
      'Health Informatics professionals with students in MSHI. The Health Informatics ' +
      'Mentorship Program is designed to support Health Informatics students with 24 credits ' +
      "from any track in Pitt's MSHI program.",
    image:
      'https://www.shrs.pitt.edu/sites/default/files/feature-image/Untitled%20design_2.jpg',
    imageText: 'featured image',
    subTitle: 'APPLICATION DEADLINE',
    subDescription:
      'The application deadline for the first rotation is June 1st and the deadline for the ' +
      'second rotation is December 1st. Register early as openings are granted on first come, first serve basis.',
  };

  const sections = [
    {
      title: 'Overview and Purpose',
      subtitle: 'Nurturing Health Informatics Leaders',
      items: {
        _: [
          'Program Objectives: Equip students for success in healthcare through education, resources, and a strong mentorship program',
          "Key Initiatives: Focus on professional development, networking, and alumni engagement to enhance students' career growth.",
          'Alumni Involvement: Integrate esteemed alumni into the mentorship program, fostering a connection between the past and the future.',
        ],
      },
      image: 'mentorship2',
    },
    {
      title: 'Student Achievements',
      subtitle: '',
      items: {
        _: [
          'The Health Informatics program at the University of Pittsburgh is committed to cultivating well-rounded professionals. ' +
            'Our program guides students to identify and achieve distinct career objectives, facilitates both personal and professional' +
            ' growth, and assists in building a strong professional network. We prioritize the development of essential, field-specific' +
            ' skills and knowledge. Our action plan includes setting clear benchmarks for student outcomes and mapping out ' +
            'the specific skills to be acquired. Through this holistic approach, we aim to produce graduates who are not ' +
            'only technically adept but also career-ready and networked in their field.',
        ],
      },
      image: 'mentorship4',
    },
    {
      title: 'Program Outcomes',
      subtitle: '',
      items: {
        _: [
          'The Health Informatics program at the University of Pittsburgh goes beyond academic training' +
            ' to deliver transformative life experiences. We aim for elevated levels of student satisfaction ' +
            'in career advancement and a heightened interest in peer and alumni connections. Our ' +
            'mentorship approach serves to cultivate a strong sense of community among students. We also seek to ' +
            'notably increase alumni engagement. Anticipated program outcomes include not only scholastic achievement ' +
            'but also personal satisfaction, stronger professional connections, and increased community involvement. ' +
            'We believe alumni play a vital role in shaping these outcomes, enriching the program with their expertise and network.',
        ],
      },
      image: 'mentorship1',
    },
    {
      title: "Mentoring: What's in it for me?",
      subtitle: 'Benefits for Mentees & Mentors',
      items: {
        'Benefits for Mentees: ': [
          'Personal and professional growth opportunities.',
          'Real-world insights from seasoned professionals.',
          'Alignment with organizational values in Health Informatics.',
          'Opportunity to network with senior professionals.',
          'Informed career choices through guided advice.',
        ],
        'Benefits for Mentors: ': [
          'Expand professional network and enhance leadership skills.',
          'Fresh challenges offering new perspectives for professional enrichment.',
          'Valuable self-reflective moments for career and personal development evaluation.',
          'Opportunity to gain insights from mentees.',
          'Improved relationships with peers through mentorship, mutually benefiting professional journey.',
        ],
      },
      image: 'mentorship3',
    },
    {
      title: 'Mentor/Mentee Requirements',
      subtitle: '',
      items: {
        'Mentor Requirements:': [
          'Complete a brief application to assist with successful matching of Mentors and Mentees.',
          "Must be graduates of Pitt's MSHI program and commit to a 6-month program, aiming for " +
            'minimum of two meetings but allowing for additional meetings based on mutual ' +
            'availability.',
          'Establish mutual goals for the relationship.',
          'Respect the confidentiality.',
          'Complete end of program survey.',
        ],
        'Mentee Requirements:': [
          'Complete a brief application to assist with successful matching of Mentors and Mentees.',
          '<strong>Must be MSHI students that have completed 24 credits from any track in the program.</strong>',
          'Establish mutual goals for the relationship.',
          'Respect the confidentiality.',
          'Complete end of program survey.',
        ],
      },
      image: '',
    },
  ];

  return (
    <>
      <Box
        maxWidth="100%"
        sx={{
          display: 'grid',
          flexDirection: 'column',
        }}
      >
        <Header title="Health Informatics" />
        <MainFeaturedPost post={mainFeaturedPost} />
        <Box mx={Mobile() ? 2 : 10}>
          <Box style={{ justifySelf: 'center' }}>
            <TimelineTable />
          </Box>
          {sections.map((section, index) => (
            <Section key={section.title} section={section} index={index} />
          ))}
          <ApplicationButtons />
          <Footer
            title="Footer"
            description="Something here to give the footer a purpose!"
          />
        </Box>
      </Box>
    </>
  );
}
