import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { toast } from 'react-hot-toast';
import { Grid, Typography } from '@mui/material';
import userServices from '../../api/services/userServices';
import MentorApplicationEditor from '../../components/ApplicationUpdate/MentorApplicationEditor';
import applicationServices from '../../api/services/applicationServices';
import MenteeApplicationEditor from '../../components/ApplicationUpdate/MenteeApplicationEditor';

export default function MyApplication() {
  const [userRole, setUserRole] = useState('');
  const [surveyData, setSurveyData] = useState();

  const initApplication = async () => {
    const me = await userServices.getMe();
    setUserRole(me.role.type);
    let application = [];
    switch (me.role.type) {
      case 'mentor':
        application = await applicationServices.getMentorApplicationByAccountId(
          me.id
        );
        break;
      case 'mentee':
        application = await applicationServices.getMenteeApplicationByAccountId(
          me.id
        );
        break;
      default:
        break;
    }
    if (application.length === 1) {
      setSurveyData(application[0]);
    }
  };

  useEffect(async () => {
    await initApplication();
  }, []);

  const updateApplication = async (updateData) => {
    switch (userRole) {
      case 'mentor':
        try {
          await applicationServices.updateMentorApplication(updateData);
          toast.success('Successfully updated!');
        } catch (e) {
          toast.error('Update failed: ' + e);
        }
        break;
      case 'mentee':
        try {
          await applicationServices.updateMenteeApplication(updateData);
          toast.success('Successfully updated!');
        } catch (e) {
          toast.error('Update failed: ' + e);
        }
        break;
      default:
        break;
    }
  };

  const handleSave = async (updateData) => {
    if (
      Number.isInteger(surveyData.user_account_id) &&
      (updateData.first_name !== surveyData.first_name ||
        updateData.last_name !== surveyData.last_name)
    ) {
      try {
        await userServices.updateUser(
          { username: updateData.first_name + ' ' + updateData.last_name },
          surveyData.user_account_id
        );
        await updateApplication(updateData);
        await initApplication();
      } catch (e) {
        toast.error('Account Update failed: ' + e);
      }
    } else {
      await updateApplication(updateData);
    }
  };

  const renderApplication = () => {
    if (surveyData !== undefined) {
      switch (userRole) {
        case 'mentor':
          return (
            <MentorApplicationEditor
              surveyData={surveyData}
              handleSave={handleSave}
            />
          );
        case 'mentee':
          return (
            <MenteeApplicationEditor
              surveyData={surveyData}
              handleSave={handleSave}
            />
          );
        default:
          return <>Error</>;
      }
    } else {
      return <>No Application Found</>;
    }
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">My Application</Typography>
        </Grid>
        <Grid item xs={12}>
          {renderApplication()}
        </Grid>
      </Grid>
    </Box>
  );
}
