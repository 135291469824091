import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { toast } from 'react-hot-toast';
import ApplicationServices from '../../api/services/applicationServices';

export default function ConfirmDelete({
  open,
  mutate,
  applicationType,
  applicationData,
  handleClose,
}) {
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleApplicationDelete = async () => {
    if (applicationData.id) {
      switch (applicationType) {
        case 'mentor':
          try {
            await ApplicationServices.deleteMentorApplication(
              applicationData.id
            );
            toast.success('Mentor has been successfully deleted');
            mutate();
          } catch (e) {
            toast.error('Error! Please try again later.' + e);
          }
          handleClose();
          break;
        case 'mentee':
          try {
            await ApplicationServices.deleteMenteeApplication(
              applicationData.id
            );
            toast.success('Mentee has been successfully deleted');
            mutate();
          } catch (e) {
            toast.error('Error! Please try again later.' + e);
          }
          handleClose();
          break;
        default:
          toast.error('Something went wrong.Please try again later.');
          handleClose();
          break;
      }
    } else {
      toast.error('Error! Missing application Id');
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want delete this application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApplicationDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
