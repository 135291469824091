/**
 *
 * @param {string} email
 * @return {string}
 */
function passwordAlgorithm(email) {
  return email.split('@')[0] + 'Abccba#';
}
/**
 * @param {number} role
 * @param {String} email
 * @param {String=} firstName - Somebody's first name
 * @param {String=} lastName - Somebody's last name
 * @param {boolean} [confirmed = true]
 * @param {boolean} [blocked = false]
 * @return {{password: string, userName: String}}
 */
function generateAccount(
  role,
  email,
  firstName,
  lastName,
  confirmed = true,
  blocked = false
) {
  const password = passwordAlgorithm(email);
  return {
    role,
    username: firstName + ' ' + lastName,
    email,
    password,
    confirmed,
    blocked,
  };
}

export { passwordAlgorithm, generateAccount };
