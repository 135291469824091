import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import parse from 'html-react-parser';

const getImage = (imageName) =>
  require('../../../assets/' + imageName + '.jpg');

export default function Section({
  section: { title, subtitle, items, image },
  index,
}) {
  const style = {
    flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
    alignItems: 'center',
    flexGrow: 1,
    marginTop: 2,
  };

  const renderParagraph = (contents) =>
    contents.length === 1 ? (
      <p>{contents[0]}</p>
    ) : (
      <ul>
        {contents.map((x) => (
          <li key={x}>{parse(x)}</li>
        ))}
      </ul>
    );

  const renderContent = () =>
    Object.entries(items).map(([key, value]) =>
      key === '_' ? (
        renderParagraph(value)
      ) : (
        <>
          <Typography variant="subtitle1">{key}</Typography>
          {renderParagraph(value)}
        </>
      )
    );

  return (
    <>
      {image !== '' ? (
        <Grid container spacing={2} sx={style}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
            <Box height={2} mb={2} bgcolor="lightgray" />
            {renderContent()}
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              style={{ objectFit: 'cover' }}
              width="90%"
              src={getImage(image)}
              alt={title}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={style}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="subtitle1">{subtitle}</Typography>
            <Box height={2} mb={2} bgcolor="lightgray" />
            {renderContent()}
          </Grid>
        </Grid>
      )}
    </>
  );
}

Section.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    items: PropTypes.objectOf(PropTypes.array.isRequired).isRequired,
    image: PropTypes.string,
  }).isRequired,
};
