import React, { useEffect, useState } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import MainCard from '../../components/MainCard';
import applicationServices from '../../api/services/applicationServices';
import userServices from '../../api/services/userServices';
import PairNote from './components/PairNote';
import StudentGrid from '../../components/StudentGrid';

export default function ContactList() {
  const [contactList, setContactList] = useState([]);
  const [showPairNote, setShowPairNote] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [userRole, setUserRole] = useState('');
  const history = useHistory();

  const refresh = async () => {
    const currentUser = await userServices.getMe();
    if (currentUser.role.name === 'Mentor') {
      setContactList(
        await applicationServices.getSelectedMenteesByMentorId(currentUser.id)
      );
      setUserRole('mentor');
    }
    if (currentUser.role.name === 'Mentee') {
      setContactList(
        await applicationServices.getSelectedMentorsByMenteeId(currentUser.id)
      );
      setUserRole('mentee');
    }
  };

  useEffect(async () => {
    await refresh();
  }, []);

  const addNote = (item) => {
    setSelectItem(item);
    setShowPairNote(true);
  };

  const headConfig = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueGetter: ({ row }) => row.first_name + ' ' + row.last_name,
    },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'pair_status', headerName: 'Status', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
      getActions: (params) => {
        const item = params.row;
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Add Note">
                <NoteAddIcon />
              </Tooltip>
            }
            onClick={() => addNote(item)}
          />,
        ];
      },
    },
  ];

  const handleCellClick = (params) => {
    switch (params.field) {
      case 'name': {
        history.push({
          pathname: `/contacter-detail/${params.row.user_account_id}`,
          state: {
            contacterData: params.row,
            contacterRole: userRole === 'mentor' ? 'mentee' : 'mentor',
            role: userRole,
          },
        });
        break;
      }
      default:
    }
  };

  return (
    <>
      <MainCard>
        <StudentGrid
          columns={headConfig}
          rows={contactList}
          // getRowId={(row) => row.id}
          onCellClick={handleCellClick}
        />
      </MainCard>
      <PairNote
        userRole={userRole}
        pairObj={selectItem}
        showPairNote={showPairNote}
        setShowPairNote={setShowPairNote}
        initData={{ note: '', note_type: 'private' }}
      />
    </>
  );
}
