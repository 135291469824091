import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import MainCard from '../../../components/MainCard';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

export default function SelectableList({
  columns,
  rows,
  rowId,
  type,
  handleSelection,
}) {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {}, [rows]);

  return (
    <MainCard>
      <DataGrid
        autoHeight
        getRowId={(row) => row[rowId]}
        rows={rows}
        columns={columns}
        slots={{ toolbar: QuickSearchToolbar }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel((prevModel) => {
            const newModel = newRowSelectionModel.filter(
              (newId) => !prevModel.includes(newId)
            );
            handleSelection(newModel, type);
            return newModel;
          });
        }}
        rowSelectionModel={rowSelectionModel}
        sx={{
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
            {
              display: 'none',
            },
        }}
      />
    </MainCard>
  );
}
