import { API } from '../api';

/**
 * @param {Object} data
 * @type {{addUser(*): Promise<AxiosResponse<any>>}}
 */
const UserServices = {
  async addUser(data) {
    return API.post('/users', data).then((res) => res.data);
  },
  async updateUser(updateData, id) {
    return API.put(`/users/${id}`, updateData).then((res) => res.data);
  },
  async deleteUser(id) {
    return API.delete(`/users/${id}`).then((res) => res.data);
  },
  async getUser() {
    return API.get('/users').then((res) => res.data);
  },
  async getRoles() {
    return API.get('/users-permissions/roles').then((res) => res.data);
  },
  async getUserById(id) {
    return API.get(`/users?id=${id}`).then((res) => res.data);
  },
  async getMe() {
    return API.get(`/users/me`).then((res) => res.data);
  },
  async getRoleId(type) {
    const roles = await UserServices.getRoles();
    let roleId = null;
    for (let i = 0; i < roles.roles.length; i += 1) {
      if (roles.roles[i].type === type) {
        roleId = parseInt(roles.roles[i].id, 10);
        break;
      }
    }
    return roleId;
  },
  async getUserByRole(role) {
    const users = await this.getUser();
    const targetUser = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(users)) {
      if (users[key].role.name === role) {
        users[key].role = role;
        targetUser.push(value);
      }
    }
    return targetUser;
  },
};

export default UserServices;
