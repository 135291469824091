export const CONFIG = {
  PORTAL: process.env.REACT_APP_API_URL,
};

export const MM = {
  MENTOR: 'MENTOR',
  MENTEE: 'MENTEE',
};

export const ApplicationStatus = {
  ACTIVATED: 'activated',
  PENDING: 'pending',
  DISCONTINUE: 'discontinue',
};

export const Term = {
  Spring: 'Spring',
  Summer: 'Summer',
  Fall: 'Fall',
};

export const Tracks = {
  DS: 'Data Science',
  HI: 'Health Informatics',
  HSM: 'Healthcare Supervision and Management',
  HSA: 'Health Services Analytics',
};

export const drawerWidth = 240;
