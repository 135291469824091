import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ActionData } from './ActionData';
import handlePair from '../../../utils/mentorshipTools';
import PairDeleteDialog from '../../../components/Popup/PairDeleteDialog';

export default function ActionBar({
  mentor,
  mentee,
  mentorId,
  menteeId,
  refresh,
}) {
  const [buttons, setButtons] = useState([
    { name: 'Pairing', action: 'Add', color: 'success' },
  ]);
  const [pairId, setPairId] = useState(null);
  const [isDisplay, setIsDisplay] = useState(false);

  const initButtons = () => {
    let pairStatus = '';
    let newPairId = null;
    const newButtonBar = [];

    if (mentorId != null && menteeId !== null) {
      for (let i = 0; i < mentor.length; i += 1) {
        if (mentor[i].user_account_id === mentorId) {
          pairStatus = mentor[i].pair_status;
          newPairId = mentor[i].id;
          break;
        }
      }
    }
    setPairId(newPairId);
    switch (pairStatus) {
      case 'Active':
        newButtonBar.push(ActionData.deactivating);
        newButtonBar.push(ActionData.concluding);
        newButtonBar.push(ActionData.removing);
        break;
      case 'Deactivate':
        newButtonBar.push(ActionData.activating);
        newButtonBar.push(ActionData.concluding);
        newButtonBar.push(ActionData.removing);
        break;
      case 'Conclude':
        newButtonBar.push(ActionData.activating);
        newButtonBar.push(ActionData.deactivating);
        newButtonBar.push(ActionData.removing);
        break;
      default:
        newButtonBar.push(ActionData.pairing);
        break;
    }
    setButtons(newButtonBar);
  };

  useEffect(() => {
    initButtons();
  }, [mentor, mentee]);

  const changePairStatus = async (updateStatus, item) => {
    await handlePair(updateStatus, mentorId, menteeId, pairId);
    await refresh();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {buttons.map((button) => (
          <Button
            variant="contained"
            key={button.name}
            fullWidth
            sx={{ padding: 1, margin: 1 }}
            color={button.color}
            onClick={async () => {
              if (button.action === ActionData.removing.action) {
                setIsDisplay(true);
              } else {
                await changePairStatus(button.action, {
                  mentor_id: mentorId,
                  mentee_id: menteeId,
                  pair_id: pairId,
                });
              }
            }}
          >
            {button.name}
          </Button>
        ))}
      </div>
      <PairDeleteDialog
        isDisplay={isDisplay}
        setIsDisplay={setIsDisplay}
        changePairStatus={changePairStatus}
        selectedItem={{
          mentor_id: mentorId,
          mentee_id: menteeId,
          pair_id: pairId,
        }}
      />
    </>
  );
}
