import React from 'react';
import { Box, Divider, Modal, Stack, Typography, Button } from '@mui/material';
import { Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '1px solid grey.100',
  boxShadow: 10,
  borderRadius: 2,
  p: 4,
  flexDirection: 'column',
  display: 'flex',
};

function UserCreateModal({ open, handleClose, handleSubmitFunction }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={modalStyle}
        component="form"
        onSubmit={handleSubmit(handleSubmitFunction)}
      >
        <Typography variant="h4">Create New Mentor Account</Typography>
        <Typography
          variant="caption"
          sx={{ mt: 0.8, fontStyle: 'italic', color: '#7e7a7a' }}
        >
          Invite a new mentor will add an application to mentor application list
          with status ACTIVATED. Meanwhile, a new user account will
          automatically be genereated. To check the account info, please click
          the name.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack spacing={2}>
          <Typography variant="h6">First Name</Typography>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <Typography variant="h6">Last Name</Typography>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
          <Typography variant="h6">Email</Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ mx: 1 }}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

UserCreateModal.propTypes = {};

export default UserCreateModal;
