import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import toast from 'react-hot-toast';
import mentorshipNotesServices from '../../../api/services/mentorshipNotesServices';

export default function PairNote({
  userRole,
  pairObj,
  showPairNote,
  setShowPairNote,
  initData,
}) {
  const [notesInput, setNotesInput] = useState(initData.note);
  const [notesType, setNotesType] = useState(initData.note_type);

  useEffect(() => {
    setNotesInput(initData.note);
    setNotesType(initData.note_type);
  }, [initData]);

  const initEmptyNote = () => {
    setNotesInput('');
    setNotesType('private');
  };

  const handleToCloseEditor = () => {
    setShowPairNote(false);
    initEmptyNote();
  };

  const handleToSaveNote = async () => {
    if (notesInput === '') {
      toast.error('Note cannot be empty');
    } else {
      const currentUser = pairObj[userRole + '_id'];
      const finalNote = {
        pair_id: pairObj.id,
        mentor_id: pairObj.mentor_id,
        mentee_id: pairObj.mentee_id,
        note: notesInput,
        note_type: notesType,
        add_by: currentUser,
        update_by: currentUser,
      };
      try {
        await mentorshipNotesServices.AddNotes(finalNote);
        toast.success('Successfully Saved!');
      } catch (e) {
        toast.error(e);
      }
      setShowPairNote(false);
      initEmptyNote();
    }
  };
  const handleToUpdateNote = async () => {
    if (notesInput === '') {
      toast.error('Note cannot be empty');
    } else {
      const currentUser = pairObj[userRole + '_id'];
      const finalNote = {
        id: initData.id,
        pair_id: pairObj.id,
        note: notesInput,
        note_type: notesType,
        update_by: currentUser,
      };
      try {
        await mentorshipNotesServices.EditNotes(finalNote);
        toast.success('Successfully Saved!');
      } catch (e) {
        toast.error(e);
      }
      setShowPairNote(false);
      initEmptyNote();
    }
  };
  const setTextBoxValue = (e) => {
    setNotesInput(e.target.value);
  };

  const handleRadioChange = (event) => {
    setNotesType(event.target.value);
  };

  return (
    <div>
      <Dialog open={showPairNote} onClose={handleToCloseEditor}>
        {initData.note === '' && (
          <DialogTitle>
            New Note for {pairObj.first_name + ' ' + pairObj.last_name}
          </DialogTitle>
        )}
        {initData.note !== '' && (
          <DialogTitle>
            Edit Note for {pairObj.first_name + ' ' + pairObj.last_name}
          </DialogTitle>
        )}
        <DialogContent style={{ paddingTop: '8px' }}>
          <FormControl hidden={initData.note !== ''}>
            <FormLabel>Note Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="note-type-radio-buttons-group"
              name="note-type-radio-buttons-group"
              value={notesType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="Private"
              />
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Public"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            label="Note"
            defaultValue={notesInput}
            multiline
            rows={4}
            fullWidth
            onBlur={setTextBoxValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToCloseEditor}>Cancel</Button>
          {initData.note === '' && (
            <Button variant="contained" onClick={handleToSaveNote}>
              Save
            </Button>
          )}
          {initData.note !== '' && (
            <Button variant="contained" onClick={handleToUpdateNote}>
              Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
