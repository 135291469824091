// eslint-disable-next-line import/prefer-default-export
export const ActionData = {
  activating: {
    name: 'Activating',
    action: 'Active',
    color: 'primary',
  },
  concluding: {
    name: 'Concluding',
    action: 'Conclude',
    color: 'secondary',
  },
  deactivating: {
    name: 'Deactivating',
    action: 'Deactivate',
    color: 'warning',
  },
  pairing: { name: 'Pairing', action: 'Add', color: 'success' },
  removing: { name: 'Removing', action: 'Remove', color: 'error' },
};
