import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AddTaskIcon from '@mui/icons-material/AddTask';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import mentorshipServices from '../../api/services/mentorshipServices';
import MainCard from '../../components/MainCard';
import ActionSign from '../MentorMenteeMatching/components/ActionSign';
import { ActionData } from '../MentorMenteeMatching/components/ActionData';
import handlePair from '../../utils/mentorshipTools';
import StudentGrid from '../../components/StudentGrid';
import PairDeleteDialog from '../../components/Popup/PairDeleteDialog';

export default function MentorMenteePairList() {
  const [mentorshipMatching, setMentorshipMatching] = useState([]);
  const [isDisplay, setIsDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const refresh = async () => {
    setMentorshipMatching(await mentorshipServices.getPairs());
  };

  useEffect(async () => {
    await refresh();
  }, []);

  const changePairStatus = async (updateStatus, item) => {
    await handlePair(updateStatus, item.mentor_id, item.mentee_id, item.id);
    await refresh();
  };

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      width: 50,
    },
    {
      field: 'pair_status',
      headerName: 'Status',
      width: 100,
      renderCell: ActionSign,
    },
    {
      field: 'mentor_name',
      headerName: 'Mentor Name',
      width: 180,
    },
    {
      field: 'mentee_name',
      headerName: 'Mentee Name',
      width: 180,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 200,
      getActions: (params) => {
        const item = params.row;
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={ActionData.activating.name}>
                <AddTaskIcon />
              </Tooltip>
            }
            label={ActionData.activating.name}
            onClick={() => changePairStatus(ActionData.activating.action, item)}
            disabled={item.pair_status == ActionData.activating.action}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={ActionData.deactivating.name}>
                <BlockIcon />
              </Tooltip>
            }
            label={ActionData.deactivating.name}
            onClick={() =>
              changePairStatus(ActionData.deactivating.action, item)
            }
            disabled={item.pair_status == ActionData.deactivating.action}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={ActionData.concluding.name}>
                <StopCircleIcon />
              </Tooltip>
            }
            label={ActionData.concluding.name}
            onClick={() => changePairStatus(ActionData.concluding.action, item)}
            disabled={item.pair_status == ActionData.concluding.action}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={ActionData.removing.name}>
                <DeleteForeverIcon />
              </Tooltip>
            }
            label={ActionData.removing.name}
            onClick={() => {
              setSelectedItem(item);
              setIsDisplay(true);
            }}
            disabled={item.pair_status == ActionData.removing.action}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Matching List</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MainCard>
              <StudentGrid columns={columns} rows={mentorshipMatching} />
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
      <PairDeleteDialog
        isDisplay={isDisplay}
        setIsDisplay={setIsDisplay}
        changePairStatus={changePairStatus}
        selectedItem={selectedItem}
      />
    </>
  );
}
