import React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

export default function PairList({ columns, rows }) {
  return (
    <DataGrid
      autoHeight
      getRowId={(row) => row.user_account_id}
      rows={rows}
      columns={columns}
      slots={{ toolbar: QuickSearchToolbar }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'created_at', sort: 'desc' }],
        },
        pagination: {
          pageSize: 50,
        },
      }}
    />
  );
}
