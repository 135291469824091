import React from 'react';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ApplicationButtons() {
  const style = {
    flexDirection: 'row',
    flexGrow: 1,
    marginTop: 2,
  };

  return (
    <Grid container spacing={4} sx={style}>
      <Grid item xs={12} sm={6}>
        <Link to="mentee-application">
          <Button variant="contained" fullWidth>
            MENTEE APPLICATION
          </Button>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Link to="mentor-application">
          <Button variant="contained" fullWidth>
            MENTOR APPLICATION
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
