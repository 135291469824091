import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import '../index.css';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { CONFIG } from '../ENV';
import { updateAuthStatus } from '../store/reducer/authenticationSlice';

console.log(process.env);

function Loginpage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageState, setPageState] = useState(true);
  const [resetEmail, setResetEmail] = useState('');

  const [logInfo, setLogInfo] = useState({
    identifier: '',
    password: '',
  });

  const errorHandle = (e) => {
    if (e && e.statusCode === 400) {
      alert('Not valid, please enter email and password again');
    } else {
      alert('Oops, something went wrong, please contact administrator');
    }
  };

  const authenticate = async () => {
    try {
      const res = await fetch(`${CONFIG.PORTAL}/auth/local`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(logInfo),
      }).catch((error) => errorHandle(error));

      const result = await res.json();

      // Update the value of react context if valid
      if (result && result.user) {
        const authenticationStatusInfo = {
          authenticationRole: result.user.role.name,
          jwtToken: result.jwt,
        };
        localStorage.setItem(
          'authenticationStatus',
          JSON.stringify(authenticationStatusInfo)
        );
        localStorage.setItem('currentUserName', result.user.username);
        dispatch(updateAuthStatus(authenticationStatusInfo));
        switch (authenticationStatusInfo.authenticationRole) {
          case 'Staff':
            history.push('/mentorship-matching', authenticationStatusInfo);
            break;
          case 'Assistant':
            history.push('/mentorship-matching', authenticationStatusInfo);
            break;
          case 'Mentor':
          case 'Mentee':
            history.push('/mentorship-portal', authenticationStatusInfo);
            break;
          default:
            alert('Not valid, please enter email and password again');
        }
      } else {
        alert('Not valid, please enter email and password again');
      }
    } catch (error) {
      errorHandle(error);
    }
  };

  const forgetClick = () => {
    setPageState(!pageState);
  };

  const resetEmailSend = async () => {
    try {
      const res = await fetch(`${CONFIG.PORTAL}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: resetEmail }),
      }).catch((error) => {
        console.log(error);
        errorHandle(error);
      });

      const result = await res.json();
      if (result && result.ok) {
        toast.success('Email sent successful!');
        setPageState(!pageState);
      } else {
        toast.error(
          `Password reset failed: ${
            result.message[0].messages[0].message || 'Unknown error'
          }`
        );
      }
    } catch (error) {
      errorHandle(error);
    }
  };

  return (
    <div>
      {/*<SiteHeader />*/}

      <Form className="login-form mt-5">
        {/*<h2 className="text-center p-3">Health Informatics Hub</h2>*/}
        <h2 className="text-center p-3">Mentorship Hub</h2>
        {pageState ? (
          <div>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter email address here"
                onChange={(e) =>
                  setLogInfo({
                    ...logInfo,
                    identifier: e.target.value,
                  })
                }
                value={logInfo.identifier}
              />
            </FormGroup>
            <div className="mb-4">
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder="Password"
                  onChange={(e) =>
                    setLogInfo({
                      ...logInfo,
                      password: e.target.value,
                    })
                  }
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      authenticate();
                    }
                  }}
                  value={logInfo.password}
                />
              </FormGroup>
            </div>
            <Button color="primary" block onClick={authenticate}>
              Sign in
            </Button>
            <div className="text-center mt-3">
              <a className="subtitle-link" onClick={() => forgetClick()}>
                Forget password
              </a>
            </div>
          </div>
        ) : (
          <div>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter email address here"
                onChange={(e) => setResetEmail(e.target.value)}
                value={resetEmail}
              />
            </FormGroup>
            <Button color="primary" block onClick={resetEmailSend}>
              Send Reset Email
            </Button>
            <div className="text-center mt-3">
              <a className="subtitle-link" onClick={() => forgetClick()}>
                Back
              </a>
            </div>
          </div>
        )}
        {/*<LoginButton />*/}
      </Form>
    </div>
  );
}

export default withRouter(Loginpage);
