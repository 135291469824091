import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormLabel from '@mui/material/FormLabel';
import { z } from 'zod';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { toast } from 'react-hot-toast';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import icon from '../../components/NavBar/favicon.ico';
import applicationServices from '../../api/services/applicationServices';
import ThankYou from './thankyou';
import { Term, Tracks } from '../../ENV';
import MeetPreference from './MeetPreference';

const schemaValidator = z.object({
  first_name: z.string().nonempty(`First Name is required`),
  last_name: z.string().nonempty(`Last Name is required`),
  // pitt_id: z.string(),
  email: z.string().nonempty(`Email is required`).email('Email is not valid'),
  phone: z
    .string()
    .transform((value) =>
      /^\d{10}$/.test(value)
        ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
        : value
    )
    .refine((value) => /\d{3}-\d{3}-\d{4}/.test(value), {
      message: 'Please follow the format 123-123-1234',
    })
    .optional()
    .or(z.literal('')),
  expect_grad_term: z.string(),
  track: z.string(),
  desired_mentor: z.string(),
  goal: z.string(),
  interests: z.string(),
});

export default function MentorApplication() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schemaValidator),
  });

  const [meetPreference, setMeetPreference] = useState('');
  const [exptGradYear, setExptGradYear] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const currentYear = new Date().getFullYear();

  const style = {
    surveyTitle: {
      width: '20 %',
      paddingRight: '100px',
      paddingTop: '200px',
    },
    survey: {
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  const onSubmitHandler = async (data) => {
    const result = {
      ...data,
      meet_preference: meetPreference,
      expect_grad_year: exptGradYear,
      invite_type: 'application',
      status: 'pending',
    };
    // console.log('submitted', result);
    try {
      await applicationServices.addMenteeApplication(result);
      setOpenDialog(true);
    } catch (e) {
      toast.error('Application submit failed: ' + e);
    }
  };

  return (
    <Box
      component="main"
      maxWidth={800}
      display="flex"
      paddingTop="120px"
      margin="auto"
    >
      <Box paddingRight="100px">
        <img alt="" src={icon} width="60" height="60" />{' '}
        <h3 className={style.surveyTitle}>Mentee Application</h3>
      </Box>
      <Box className={style.survey}>
        <form
          onSubmit={handleSubmit(onSubmitHandler, () => {
            console.log(errors);
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('first_name')}
                autoComplete="given-name"
                variant="outlined"
                fullWidth
                label="*First Name"
                autoFocus
                error={!!errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('last_name')}
                variant="outlined"
                fullWidth
                label="*Last Name"
                autoComplete="family-name"
                error={!!errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <TextField*/}
            {/*    {...register('pitt_id')}*/}
            {/*    variant="outlined"*/}
            {/*    fullWidth*/}
            {/*    label="Pitt ID"*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <TextField
                {...register('email')}
                variant="outlined"
                type="email"
                fullWidth
                label="*Email Address"
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('phone')}
                variant="outlined"
                fullWidth
                label="Phone"
                autoComplete="phone"
                error={!!errors.phone}
                helperText={
                  errors.phone ? errors.phone.message : 'Example: 123-123-1234'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year']}
                  label="Expect Graduate Year"
                  minDate={new Date((currentYear - 5).toString() + '-01-01')}
                  maxDate={new Date((currentYear + 5).toString() + '-12-31')}
                  value={
                    exptGradYear
                      ? new Date(exptGradYear.toString() + '-12-31')
                      : null
                  }
                  onChange={(newValue) => {
                    setExptGradYear(new Date(newValue.$d).getFullYear());
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="term-label">Expect Graduate Term</InputLabel>
                <Select
                  {...register('expect_grad_term')}
                  labelId="term-label"
                  id="term"
                  label="Expect Graduate Term"
                  defaultValue={null}
                >
                  {Object.entries(Term).map(([key, value]) => (
                    <MenuItem value={key} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="track-label">Track</InputLabel>
                <Select
                  {...register('track')}
                  labelId="track-label"
                  id="track"
                  label="Track"
                  defaultValue=""
                >
                  {Object.entries(Tracks).map(([key, value]) => (
                    <MenuItem value={key} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MeetPreference
                meetPreference={meetPreference}
                setMeetPreference={setMeetPreference}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">
                Please specify if you prefer a mentor with a specific background
                (e.g., no preference, gender, specific field). While we cannot
                guarantee a match, we will do our best to identify a potential
                mentor.{' '}
              </FormLabel>
              <TextField
                {...register('desired_mentor')}
                label="Desired Mentor Demographics"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('goal')}
                label="Professional Goals"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('interests')}
                label="Interests or Hobbies"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*  Your Website*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}.
          </Typography>
        </Box>
      </Box>
      <ThankYou isOpen={openDialog} />
    </Box>
  );
}
