import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import ApplicationServices from '../../api/services/applicationServices';
import mentorshipNotesServices from '../../api/services/mentorshipNotesServices';
import userServices from '../../api/services/userServices';
import mentorshipServices from '../../api/services/mentorshipServices';

export default function ConfirmStrictDelete({
  open,
  mutate,
  applicationType,
  applicationData,
  handleClose,
}) {
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);
  const handleApplicationDelete = async () => {
    // step 1. delete all notes
    // step 2. delete all relationships (mentor-mentee relationship)
    // step 3. delete account
    // step 4. delete application
    if (applicationData.id && applicationData.user_account_id) {
      switch (applicationType) {
        case 'mentor':
          try {
            // step 1
            await mentorshipNotesServices.DeleteNotesByMentorId(
              applicationData.user_account_id
            );
            // step 2
            await mentorshipServices.deletePairByMentorId(
              applicationData.user_account_id
            );
            // step 3
            await userServices.deleteUser(applicationData.user_account_id);
            // step 4
            await ApplicationServices.deleteMentorApplication(
              applicationData.id
            );
            toast.success('Mentor has been successfully deleted');
            setOpenDialog(false);
            mutate();
          } catch (e) {
            toast.error('Error! ' + e);
          }
          break;
        case 'mentee':
          try {
            // step 1
            await mentorshipNotesServices.DeleteNotesByMenteeId(
              applicationData.user_account_id
            );
            // step 2
            await mentorshipServices.deletePairByMenteeId(
              applicationData.user_account_id
            );
            // step 3
            await userServices.deleteUser(applicationData.user_account_id);
            // step 4
            await ApplicationServices.deleteMenteeApplication(
              applicationData.id
            );
            toast.success('Mentee has been successfully deleted');
            setOpenDialog(false);
            mutate();
          } catch (e) {
            toast.error('Error! ' + e);
          }
          break;
        default:
          toast.error('Something went wrong.Please try again later.');
          handleClose();
      }
    } else {
      toast.error('Error! Missing application Id or account Id.');
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete this application will also{' '}
            <strong style={{ color: 'red' }}>delete the account</strong>,{' '}
            <strong style={{ color: 'red' }}>
              all notes created by this account
            </strong>
            ,{' '}
            <strong style={{ color: 'red' }}>
              all notes related to this account
            </strong>{' '}
            and{' '}
            <strong style={{ color: 'red' }}>
              all the mentor-mentee relations
            </strong>
            . Do you still want delete this application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApplicationDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
