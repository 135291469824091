import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { useSelector } from 'react-redux';
import Loginpage from './pages/Loginpage';
import PrivateRoute from './components/PrivateRoute';
import ThemeCustomization from './themes';
import { SelectionsContextProvider } from './context/SelectionsContext';
import MentorshipProgram from './pages/MentorMenteeLanding';
import { CertificationContextProvider } from './context/CertificationContext';
import MainLayout from './layout/MainLayout';
import ScrollTop from './components/ScrollTop';
import MentorMenteeList from './pages/MentorMenteeList';
import MentorApplication from './pages/MentorshipApplication/mentorApplication';
import MenteeApplication from './pages/MentorshipApplication/menteeApplication';
import MentorMenteePortal from './pages/MentorMenteePortal';
import MentorMenteeDetailPage from './pages/MentorMenteeInfoView/MentorMenteeDetailPage';
import MentorMenteeMatching from './pages/MentorMenteeMatching';
import MentorMenteePairList from './pages/MentorMenteePairList';
import ContactList from './pages/MentorMenteePortal/ContactList';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ContacterDetail from './pages/MentorMenteePortal/ContacterDetail';
import MyApplication from './pages/MyApplication';

function App() {
  const authenticationStatus = useSelector(
    (state) => state.authenticationStatus
  );

  const role = authenticationStatus.authenticationRole;

  let redirectPathAfterLogin = '/';
  switch (authenticationStatus.authenticationRole) {
    case 'Staff':
      redirectPathAfterLogin = '/mentorship-matching';
      break;
    case 'Assistant':
      redirectPathAfterLogin = '/mentorship-matching';
      break;
    case 'Mentor':
      redirectPathAfterLogin = '/mentorship-portal';
      break;
    case 'Mentee':
      redirectPathAfterLogin = '/mentorship-portal';
      break;
    default:
      break;
  }

  return (
    <Router basename={process.env.PUBLIC_URL || ''}>
      <ScrollTop>
        <ThemeCustomization>
          <Switch>
            <Route path="/" exact>
              <MentorshipProgram />
            </Route>
            <Route path="/mentor-application">
              <MentorApplication />
            </Route>
            <Route path="/mentee-application">
              <MenteeApplication />
            </Route>
            <Route>
              {authenticationStatus.isExpired && <Redirect to="/login" />}
              {role !== false ? (
                <SelectionsContextProvider>
                  <CertificationContextProvider>
                    <MainLayout>
                      <Switch>
                        {/*<PrivateRoute path="/" exact>*/}
                        {/*  <Redirect to={redirectPathAfterLogin} />*/}
                        {/*</PrivateRoute>*/}
                        <PrivateRoute path="/login" exact>
                          <Redirect to={redirectPathAfterLogin} />
                        </PrivateRoute>
                        <PrivateRoute path="/mentorship-portal">
                          <MentorMenteePortal />
                        </PrivateRoute>
                        <PrivateRoute path="/mentor-list">
                          <MentorMenteeList />
                        </PrivateRoute>
                        <PrivateRoute path="/mentee-list">
                          <MentorMenteeList />
                        </PrivateRoute>
                        <PrivateRoute path="/mentorship-matching">
                          <MentorMenteeMatching />
                        </PrivateRoute>
                        <PrivateRoute path="/mentorship-pair">
                          <MentorMenteePairList />
                        </PrivateRoute>
                        <PrivateRoute path="/application-detail/:id">
                          <MentorMenteeDetailPage />
                        </PrivateRoute>
                        <PrivateRoute path="/contact-list">
                          <ContactList />
                        </PrivateRoute>
                        <PrivateRoute path="/contacter-detail/:id">
                          <ContacterDetail />
                        </PrivateRoute>
                        <PrivateRoute path="/my-application">
                          <MyApplication />
                        </PrivateRoute>
                      </Switch>
                    </MainLayout>
                  </CertificationContextProvider>
                </SelectionsContextProvider>
              ) : (
                <>
                  <Route path="/login" exact>
                    <Loginpage />
                  </Route>
                  {/*  Reset Password  */}
                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>
                </>
              )}
            </Route>
          </Switch>
        </ThemeCustomization>
      </ScrollTop>
    </Router>
  );
}

export default App;
