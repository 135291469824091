import axios from 'axios';
import store from '../store/store';
import { CONFIG } from '../ENV';

const baseURL = CONFIG.PORTAL;

const userStatus = {
  get userRole() {
    const { authenticationStatus } = store.getState();
    return authenticationStatus.authenticationRole;
  },
  get userJwtToken() {
    const { authenticationStatus } = store.getState();
    return authenticationStatus.jwtToken;
  },
  userName: localStorage.getItem('currentUserName'),
};

const API = axios.create({
  baseURL,
  withCredentials: false,
});

API.interceptors.request.use(
  (req) => {
    if (userStatus.userJwtToken)
      req.headers.Authorization = `Bearer ${userStatus.userJwtToken}`;
    return req;
  },
  (error) => Promise.reject(error)
);

export { API, userStatus };
