import React, { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/system';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Tracks } from '../../ENV';
import MeetPreference from '../../pages/MentorshipApplication/MeetPreference';

export default function MentorApplicationEditor({ surveyData, handleSave }) {
  const schemaValidator = z.object({
    first_name: z.string().nonempty(`First Name is required`),
    last_name: z.string().nonempty(`Last Name is required`),
    email: z.string().nonempty(`Email is required`).email('Email is not valid'),
    phone: z
      .string()
      .transform((value) =>
        /^\d{10}$/.test(value)
          ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
          : value
      )
      .refine((value) => /\d{3}-\d{3}-\d{4}/.test(value), {
        message: 'Please follow the format 123-123-1234',
      })
      .optional()
      .or(z.literal('')),
    experience: z.string(),
    career_accomplishments: z.string(),
    skills: z.string(),
    interests: z.string(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schemaValidator),
    defaultValues: {
      first_name: surveyData.first_name,
      last_name: surveyData.last_name,
      email: surveyData.email,
      phone: surveyData.phone ? surveyData.phone : '',
      experience: surveyData.experience ? surveyData.experience : '',
      career_accomplishments: surveyData.career_accomplishments
        ? surveyData.career_accomplishments
        : '',
      skills: surveyData.skills ? surveyData.skills : '',
      interests: surveyData.interests ? surveyData.interests : '',
    },
  });
  const [newTrack, setNewTrack] = useState(
    surveyData.track ? surveyData.track : ''
  );
  const trackChange = (event) => {
    setNewTrack(event.target.value);
  };

  const [meetPreference, setMeetPreference] = useState(
    surveyData.meet_preference || ''
  );

  const onUpdateHandler = async (data) => {
    const updateData = {
      ...data,
      track: newTrack,
      id: surveyData.id,
      meet_preference: meetPreference,
    };
    handleSave(updateData);
  };

  return (
    <Box paddingTop={2} paddingBottom={2}>
      <form onSubmit={handleSubmit(onUpdateHandler, () => {})}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              {...register('first_name')}
              label="First Name"
              error={!!errors.first_name}
              helperText={errors.first_name && errors.first_name.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              {...register('last_name')}
              label="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name && errors.last_name.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register('email')}
              variant="filled"
              type="email"
              fullWidth
              label="Email Address"
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              {...register('phone')}
              fullWidth
              label="Phone"
              error={!!errors.phone}
              helperText={
                errors.phone ? errors.phone.message : 'Example: 123-123-1234'
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="track-label">Track</InputLabel>
              <Select
                labelId="track-label"
                id="track"
                label="Track"
                value={newTrack}
                onChange={trackChange}
              >
                {Object.entries(Tracks).map(([key, value]) => (
                  <MenuItem value={key} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <MeetPreference
              meetPreference={meetPreference}
              setMeetPreference={setMeetPreference}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('experience')}
              label="Mentoring Experience"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('career_accomplishments')}
              label="Career Accomplishments"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('skills')}
              label="Professional Skills"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...register('interests')}
              label="Hobbies or Interests"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Box width="100%" />
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Detail
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
