import React from 'react';
import {
  Stack,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@mui/material';
import { Mobile } from '../../../layout/BreakPoint';

const rows = [
  {
    task: 'Applications Accepted',
    date: 'First Rotation: May 1st - June 1st/Second Rotation: November 1st - December 1st',
  },
  {
    task: 'Match is Determined',
    date: 'First Rotation: June 15th/Second Rotation: January 1st',
  },
  {
    task: 'First Rotation',
    date: 'July 1st- December 1st',
  },
  {
    task: 'Second Rotation',
    date: 'January 15th- June 1st',
  },
];

export default function TimelineTable() {
  return (
    <Stack spacing={2}>
      <Typography variant="h2">Timeline/Schedule</Typography>
      {/*<Box height={2} bgcolor="lightgray" />*/}
      <TableContainer
        component={Paper}
        sx={{
          width: Mobile() ? '100%' : '60%',
          justifyContent: 'center',
        }}
      >
        <Table aria-label="timeline table">
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.task}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.task}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.date.split('/').map((curs, idx) => (
                    <p key={idx}>{curs}</p>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
