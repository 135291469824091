import React, { useEffect, useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import toast from 'react-hot-toast';
import MainCard from '../../components/MainCard';
import PairNote from './components/PairNote';
import StudentGrid from '../../components/StudentGrid';
import mentorshipNotesServices from '../../api/services/mentorshipNotesServices';

export default function ContacterDetail() {
  const { state } = useLocation();
  const [myNoteList, setMyNoteList] = useState([]);
  const [myPublicNoteList, setMyPublicNoteList] = useState([]);
  const { contacterData, contacterRole, role } = state;
  const [showPairNote, setShowPairNote] = useState(false);
  const [constructedNote, setConstructedNote] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const getMyNoteList = async () => {
    setMyNoteList(
      await mentorshipNotesServices.GetMyPrivateNotes(
        contacterData[role + '_id'],
        contacterData.id
      )
    );
  };

  const getPublicNoteList = async () => {
    setMyPublicNoteList(
      await mentorshipNotesServices.GetMyPublicNotes(
        contacterData[role + '_id'],
        contacterData.id
      )
    );
  };

  useEffect(async () => {
    await getMyNoteList();
    await getPublicNoteList();
  }, [showPairNote]);

  const addNote = () => {
    setShowPairNote(true);
    setConstructedNote({
      note: '',
      note_type: 'private',
    });
  };

  const editNote = (item) => {
    setConstructedNote({
      id: item.id,
      note: item.note,
      note_type: item.note_type,
    });
    setShowPairNote(true);
  };

  const deleteNote = async () => {
    setShowDeleteDialog(false);
    try {
      await mentorshipNotesServices.DeleteNotes(deleteItem.id);
      toast.success('Successfully deleted!');
    } catch (e) {
      toast.error(e);
    }
    setDeleteItem({});
    await getMyNoteList();
  };

  const noteHeadConfig = [
    { field: 'id', headerName: 'id', width: 50 },
    { field: 'note', headerName: 'Note', width: 500 },
    { field: 'note_type', headerName: 'Type', width: 80 },
    {
      field: 'add_by',
      headerName: 'Created by',
      width: 180,
      valueGetter: ({ row }) =>
        row.add_by === contacterData[role + '_id']
          ? 'me'
          : contacterData.first_name + ' ' + contacterData.last_name,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 160,
      valueGetter: ({ row }) =>
        new Date(row.created_at).toLocaleString('en-US', {
          timeZone: 'America/New_York',
        }),
    },
    {
      field: 'action',
      headerName: 'Actions',
      type: 'actions',
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
      sortable: false,
      getActions: (params) => {
        const item = params.row;
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit Note">
                <EditIcon />
              </Tooltip>
            }
            onClick={() => editNote(item)}
            disabled={item.add_by !== contacterData[role + '_id']}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete Note">
                <DeleteOutlineIcon />
              </Tooltip>
            }
            onClick={() => {
              setShowDeleteDialog(true);
              setDeleteItem(item);
            }}
            disabled={item.add_by !== contacterData[role + '_id']}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <MainCard>
        <Typography variant="h5">
          {role === 'mentor' ? 'Mentee' : 'Mentor'} Detail
          <IconButton color="primary" onClick={() => addNote()}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Typography>
        <Grid container>
          <Grid item xs={3}>
            First Name: {contacterData.first_name}
          </Grid>
          <Grid item xs={3}>
            Last Name: {contacterData.last_name}
          </Grid>
          <Grid item xs={3}>
            Email: {contacterData.email}
          </Grid>
          <Grid item xs={3}>
            Phone: {contacterData.phone}
          </Grid>
        </Grid>
      </MainCard>
      <MainCard>
        <Typography variant="h5">My Notes</Typography>
        <StudentGrid
          // getRowId={(row) => row.id}
          rows={myNoteList}
          columns={noteHeadConfig}
        />
      </MainCard>
      <MainCard>
        <Typography variant="h5">Public Notes</Typography>
        <StudentGrid
          // getRowId={(row) => row.id}
          rows={myPublicNoteList}
          columns={noteHeadConfig}
        />
      </MainCard>
      <PairNote
        userRole={role}
        pairObj={contacterData}
        showPairNote={showPairNote}
        setShowPairNote={setShowPairNote}
        initData={constructedNote}
      />
      <Dialog open={showDeleteDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Delete Note</DialogTitle>
        <DialogContent>
          <Typography>Want to delete the note?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setShowDeleteDialog(false);
              setDeleteItem({});
            }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => deleteNote()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
