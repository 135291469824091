import { API } from '../api';

const mentorshipNotesServices = {
  async AddNotes(noteData) {
    return API.post(`/mentorship-notes`, noteData).then((res) => res.data);
  },

  async DeleteNotes(itemId) {
    return API.delete(`/mentorship-notes/${itemId}`).then((res) => res.data);
  },

  async EditNotes(noteData) {
    return API.put(`/mentorship-notes/${noteData.id}`, noteData).then(
      (res) => res.data
    );
  },

  async DeleteNotesByMentorId(mentorId) {
    return API.delete(`/mentorship-notes/delete-mentor-notes/${mentorId}`).then(
      (res) => res.data
    );
  },

  async DeleteNotesByMenteeId(menteeId) {
    return API.delete(`/mentorship-notes/delete-mentee-notes/${menteeId}`).then(
      (res) => res.data
    );
  },

  async DeleteNotesByPairId(pairId) {
    return API.delete(`/mentorship-notes/delete-pair-notes/${pairId}`).then(
      (res) => res.data
    );
  },

  async GetMyPrivateNotes(accountId, pairId) {
    return API.post(`/mentorship-notes/getMyPrivateNotes`, {
      accountId,
      pairId,
    }).then((res) => res.data);
  },

  async GetMyPublicNotes(accountId, pairId) {
    return API.post(`/mentorship-notes/getMyPublicNotes`, {
      accountId,
      pairId,
    }).then((res) => res.data);
  },
};

export default mentorshipNotesServices;
