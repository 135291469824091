import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import Badge from '@mui/material/Badge';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import MainCard from '../../components/MainCard';
import StudentGrid from '../../components/StudentGrid';
import ApplicationServices from '../../api/services/applicationServices';
import UserCreateModal from './UserCreateModal';
import UserServices from '../../api/services/userServices';
import { generateAccount } from '../../utils/generalTools';
import ConfirmDelete from './confirmDelete';
import ConfirmStrictDelete from './confirmStrictDelete';

const fabStyle = {
  margin: 0,
  top: 'auto',
  right: 25,
  bottom: 20,
  left: 'auto',
  zIndex: 1500,
  position: 'absolute',
};

export default function MentorMenteeList() {
  const location = useLocation();
  const history = useHistory();
  const listType = location.pathname.split('-')[0].slice(1);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openStrictConfirm, setOpenStrictConfirm] = useState(false);
  const [selectedApplicationData, setSelectedApplicationData] = useState({});

  const { data, error, isLoading, mutate } = useSWR(
    ['/' + listType + '-applications'],
    ApplicationServices.getApplicationList(listType)
  );

  const standardHead = [
    {
      field: 'status',
      headerName: '',
      width: 10,
      align: 'center',
      renderCell: (params) => {
        const colorTable = {
          activated: 'primary',
          discontinue: 'error',
          pending: 'secondary',
        };
        return (
          <Badge color={colorTable[params.formattedValue]} variant="dot" />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      valueGetter: ({ row }) => row.first_name + ' ' + row.last_name,
    },
    { field: 'email', headerName: 'Email', width: 160 },
    { field: 'phone', headerName: 'Phone', width: 100 },
    { field: 'track', headerName: 'Track', width: 80 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 150,
      type: 'dateTime',
      valueGetter: ({ value }) =>
        value && new Date(new Date(value).toLocaleString()),
    },
    { field: 'meet_preference', headerName: 'Preference', width: 100 },
  ];
  const [headConfig, setHeadConfig] = useState(standardHead);
  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedApplicationData({});
  };
  const handleConfirmOpen = (applicationData) => {
    setOpenConfirm(true);
    setSelectedApplicationData(applicationData);
  };
  const handleStrictConfirmClose = () => {
    setOpenStrictConfirm(false);
    setSelectedApplicationData({});
  };
  const handleStrictConfirmOpen = (applicationData) => {
    setOpenStrictConfirm(true);
    setSelectedApplicationData(applicationData);
  };

  useEffect(() => {
    switch (listType) {
      case 'mentor':
        setHeadConfig([
          ...standardHead,
          { field: 'experience', headerName: 'Experience', width: 120 },
          {
            field: 'career_accomplishments',
            headerName: 'Accomplishments',
            width: 120,
          },
          {
            field: 'skills',
            headerName: 'Professional Skills',
            width: 140,
          },
          { field: 'interests', headerName: 'Interests', width: 120 },
          {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete Application">
                    <DeleteIcon />
                  </Tooltip>
                }
                onClick={
                  typeof params.row.user_account_id === 'number'
                    ? () => handleStrictConfirmOpen(params.row)
                    : () => handleConfirmOpen(params.row)
                }
                label="Delete"
              />,
            ],
          },
        ]);
        break;
      case 'mentee':
        setHeadConfig([
          ...standardHead,
          // { field: 'pitt_id', headerName: 'PittID', width: 100 },
          {
            field: 'expect_grad',
            headerName: 'Expect Graduation',
            width: 100,
            valueGetter: ({ row }) =>
              (row.expect_grad_year ? row.expect_grad_year : '') +
              ' ' +
              (row.expect_grad_term ? row.expect_grad_term : ''),
          },
          { field: 'desired_mentor', headerName: 'Desired Mentor', width: 140 },
          { field: 'goal', headerName: 'Goal', width: 120 },
          { field: 'interests', headerName: 'Interests', width: 120 },
          {
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Delete Application">
                    <DeleteIcon />
                  </Tooltip>
                }
                onClick={
                  typeof params.row.user_account_id === 'number'
                    ? () => handleStrictConfirmOpen(params.row)
                    : () => handleConfirmOpen(params.row)
                }
                label="Delete"
              />,
            ],
          },
        ]);
        break;
      default:
        setHeadConfig([]);
        break;
    }
  }, [listType]);

  const handleCellClick = (params) => {
    switch (params.field) {
      case 'name': {
        history.push({
          pathname: `/application-detail/${params.id}`,
          state: {
            applicationData: data.filter((item) => item.id === params.id),
            type: listType,
          },
        });
        break;
      }
      default:
    }
  };

  const handleCreateUserClose = () => setOpenCreateUser(false);
  const handleCreateUserOpen = () => setOpenCreateUser(true);

  const handleCreateUser = async (formData) => {
    const roleId = await UserServices.getRoleId('mentor');
    // 1. Insert into user table
    const addUser = async () =>
      UserServices.addUser(
        generateAccount(
          roleId,
          formData.email,
          formData.firstName,
          formData.lastName
        )
      );

    // 2. Insert user into mentor table
    const addMentorApplication = async (userId) =>
      ApplicationServices.addMentorApplication({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        invite_type: 'invited',
        status: 'activated',
        user_account_id: userId,
      });

    await toast
      .promise(addUser(), {
        loading: 'Loading...',
        success: 'User Created Successfully',
        error: 'Attention: User can NOT be created',
      })
      .then((res) => {
        toast.promise(addMentorApplication(res.id), {
          loading: 'Loading...',
          success: 'Mentor Application Added',
          error: 'Attention: can NOT add application',
        });
      })
      .then(() => {
        // console.log('Executing Revalidation function');
        mutate();
        handleCreateUserClose();
      });
  };

  return (
    <Grid container>
      {/* Title */}
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">
            {listType.charAt(0).toUpperCase() + listType.slice(1)} Application
            List
          </Typography>
        </Grid>
      </Grid>

      {/*Table */}
      {isLoading && <LinearProgress />}
      {!isLoading && !error && (
        <Grid item xs={12} sx={{ mt: 2 }}>
          <MainCard sx={{ p: 0 }}>
            <div>
              <StudentGrid
                rows={data}
                columns={headConfig}
                onCellClick={handleCellClick}
              />
            </div>
          </MainCard>
        </Grid>
      )}

      {listType === 'mentor' ? (
        <ButtonGroup style={fabStyle}>
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={handleCreateUserOpen}
          >
            <Add sx={{ mr: 0.5 }} />
            Invite Mentor
          </Button>
        </ButtonGroup>
      ) : (
        <></>
      )}

      <UserCreateModal
        open={openCreateUser}
        handleClose={handleCreateUserClose}
        handleSubmitFunction={handleCreateUser}
      />
      <ConfirmDelete
        open={openConfirm}
        mutate={mutate}
        applicationType={listType}
        applicationData={selectedApplicationData}
        handleClose={handleConfirmClose}
      />
      <ConfirmStrictDelete
        open={openStrictConfirm}
        mutate={mutate}
        applicationType={listType}
        applicationData={selectedApplicationData}
        handleClose={handleStrictConfirmClose}
      />
    </Grid>
  );
}
