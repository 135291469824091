import React from 'react';
import Badge from '@mui/material/Badge';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOff from '@mui/icons-material/PersonOff';
import PersonOutline from '@mui/icons-material/PersonOutline';
import { ActionData } from './ActionData';

export default function ActionSign(param) {
  switch (param.row.pair_status) {
    case 'Active':
      return (
        <Badge
          color={ActionData.activating.color}
          badgeContent=" "
          variant="dot"
        >
          <HowToRegIcon />
        </Badge>
      );
    case 'Deactivate':
      return (
        <Badge
          color={ActionData.deactivating.color}
          badgeContent=" "
          variant="dot"
        >
          <PersonOff />
        </Badge>
      );
    case 'Conclude':
      return (
        <Badge
          color={ActionData.concluding.color}
          badgeContent=" "
          variant="dot"
        >
          <PersonOutline />
        </Badge>
      );
    default:
      return <></>;
  }
}
