import { API } from '../api';

const ApplicationServices = {
  addMentorApplication(applicationData) {
    return API.post(`/mentor-applications`, applicationData).then(
      (res) => res.data
    );
  },

  updateMentorApplication(applicationData) {
    return API.put(
      `/mentor-applications/${applicationData.id}`,
      applicationData
    ).then((res) => res.data);
  },

  addMenteeApplication(applicationData) {
    return API.post(`/mentee-applications`, applicationData).then(
      (res) => res.data
    );
  },

  updateMenteeApplication(applicationData) {
    return API.put(
      `/mentee-applications/${applicationData.id}`,
      applicationData
    ).then((res) => res.data);
  },

  getApplicationList(type) {
    if (type === 'mentor') {
      return this.getMentorApplication;
    }
    if (type === 'mentee') {
      return this.getMenteeApplication;
    }
    return [];
  },

  // getMenteesByMentorId
  async getMenteesByMentorId(id) {
    return API.post(`/mentor-applications/getMenteesByMentorId`, { id }).then(
      (res) => res.data
    );
  },

  // getMentorsByMenteeId
  async getMentorsByMenteeId(id) {
    return API.post(`/mentee-applications/getMentorsByMenteeId`, { id }).then(
      (res) => res.data
    );
  },
  // getSelectedMenteesByMentorId
  async getSelectedMenteesByMentorId(id) {
    return API.post(`/mentor-applications/getSelectedMenteesByMentorId`, {
      id,
    }).then((res) => res.data);
  },

  // getSelectedMentorsByMenteeId
  async getSelectedMentorsByMenteeId(id) {
    return API.post(`/mentee-applications/getSelectedMentorsByMenteeId`, {
      id,
    }).then((res) => res.data);
  },

  async getMentorApplication() {
    return API.post(`/mentor-applications/getAllApplicationAndAccount`).then(
      (res) => res.data
    );
  },

  async getMenteeApplication() {
    return API.post(`/mentee-applications/getAllApplicationAndAccount`).then(
      (res) => res.data
    );
  },

  async getMentorApplicationByAccountId(accountId) {
    return API.get(`/mentor-applications/getByAccountId/${accountId}`).then(
      (res) => res.data
    );
  },

  async getMenteeApplicationByAccountId(accountId) {
    return API.get(`/mentee-applications/getByAccountId/${accountId}`).then(
      (res) => res.data
    );
  },

  setApplication(type, id, config) {
    if (type === 'mentor') {
      return this.setMentorApplication(id, config);
    }
    if (type === 'mentee') {
      return this.setMenteeApplication(id, config);
    }
    return null;
  },

  async setMentorApplication(id, config) {
    return API.put(`/mentor-applications/${id}`, config).then(
      (res) => res.data
    );
  },

  async setMenteeApplication(id, config) {
    return API.put(`/mentee-applications/${id}`, config).then(
      (res) => res.data
    );
  },

  async deleteMentorApplication(id) {
    return API.delete(`/mentor-applications/${id}`).then((res) => res.data);
  },

  async deleteMenteeApplication(id) {
    return API.delete(`/mentee-applications/${id}`).then((res) => res.data);
  },
};

export default ApplicationServices;
