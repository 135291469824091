import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';

export default function MeetPreference({ meetPreference, setMeetPreference }) {
  const initMeetPreference = () => {
    const selections = { Days: false, Evenings: false, Weekends: false };
    if (meetPreference !== '') {
      const preferences = meetPreference.split(',');
      preferences.forEach((preference) => {
        selections[preference] = true;
      });
    }
    return selections;
  };
  const [meetSelections, setMeetSelections] = useState(initMeetPreference);

  const getMeetPreference = (result) => {
    const preference = [];
    Object.keys(result).forEach((item) => {
      if (result[item]) {
        preference.push(item);
      }
    });
    return preference.toString();
  };

  const handleCheckbox = (e) => {
    const itemName = e.target.name;
    const newSelection = {
      ...meetSelections,
      [itemName]: !meetSelections[itemName],
    };
    setMeetPreference(getMeetPreference(newSelection));
    setMeetSelections(newSelection);
  };

  return (
    <FormGroup>
      <FormLabel component="legend">Best time to meet</FormLabel>
      {Object.keys(meetSelections).map((item) => (
        <FormControlLabel
          key={item}
          control={
            <Checkbox
              checked={meetSelections[item]}
              onChange={handleCheckbox}
              name={item}
            />
          }
          label={item}
        />
      ))}
    </FormGroup>
  );
}
