import toast from 'react-hot-toast';
import mentorshipServices from '../api/services/mentorshipServices';
import mentorshipNotesServices from '../api/services/mentorshipNotesServices';

async function handlePair(status, mentorId, menteeId, pairId) {
  if (mentorId !== null && menteeId !== null) {
    switch (status) {
      case 'Add':
        try {
          await mentorshipServices.addPair(mentorId, menteeId);
          toast.success('Successfully added.');
        } catch (e) {
          toast.error('Pair adding failed: ', e);
        }
        break;
      case 'Active':
      case 'Deactivate':
      case 'Conclude':
        try {
          await mentorshipServices.modifyStatus(pairId, status);
          toast.success('Successfully updated.');
        } catch (e) {
          toast.error('Pair updating failed: ', e);
        }
        break;
      case 'Remove':
        try {
          await mentorshipNotesServices.DeleteNotesByPairId(pairId);
          await mentorshipServices.deletePairByPairId(pairId);
          toast.success('Successfully updated.');
        } catch (e) {
          toast.error('Pair deleting failed: ', e);
        }
        break;
      default:
        break;
    }
  } else {
    toast.error('Please have both mentor and mentee selected');
  }
}

export default handlePair;
