import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Mobile } from '../../../layout/BreakPoint';
import icon from '../../../components/NavBar/favicon.ico';
import firstMeetingTool from '../../../assets/MSHI Mentorship-First Meeting Tool.docx';
import meetingTool from '../../../assets/MSHI Mentorship-Meeting Tool.docx';
import questionnaire from '../../../assets/Mentor and Mentee Questionnaire.docx';

export default function Header(props) {
  const { title } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [opened, setOpened] = useState(false);

  // eslint-disable-next-line no-shadow
  const toggleDrawer = (opened) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpened(opened);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div
          style={{
            width: '100%',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img alt="" src={icon} width="40" height="40" />
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            sx={{ flex: 1, p: 2 }}
          >
            {title}
          </Typography>
          <Box>
            <ul
              style={{
                display: Mobile() ? 'none' : 'flex',
                flexWrap: 'wrap',
                float: 'right',
                margin: '0px',
                padding: '0px',
                overflow: 'hidden',
                listStyleType: 'none',
                marginLeft: '20px',
              }}
            >
              <li style={{ paddingLeft: '4px' }}>
                <Link to="mentor-application">
                  <Button
                    id="basic-button"
                    onClick={handleClick}
                    style={{
                      color: '#003594',
                    }}
                    variant="outlined"
                  >
                    <strong>Mentor Application</strong>
                  </Button>
                </Link>
              </li>
              <li style={{ paddingLeft: '4px' }}>
                <Link to="mentee-application">
                  <Button
                    id="basic-button"
                    onClick={handleClick}
                    style={{
                      color: '#003594',
                    }}
                    variant="outlined"
                  >
                    <strong>Mentee Application</strong>
                  </Button>
                </Link>
              </li>
              <li style={{ paddingLeft: '4px' }}>
                <Button
                  id="basic-button"
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onMouseOver={handleClick}
                  onClick={handleClick}
                  style={{ color: '#003594' }}
                  variant="outlined"
                >
                  <strong>Tools</strong>
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    onMouseLeave: handleClose,
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <a
                      href={firstMeetingTool}
                      download="MSHI Mentorship-First Meeting Tool"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      First Meeting Tool
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      href={meetingTool}
                      download="MSHI Mentorship-Meeting Tool"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      Meeting Tool
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      href={questionnaire}
                      download="Mentor and Mentee Questionnaire"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      Mentor & Mentee Questionnaire
                    </a>
                  </MenuItem>
                </Menu>
              </li>
              <li style={{ paddingLeft: '4px' }}>
                <Link to="/login">
                  <Button
                    style={{ textDecoration: 'none', color: '#003594' }}
                    variant="outlined"
                  >
                    <strong>Login</strong>
                  </Button>
                </Link>
              </li>
            </ul>
          </Box>

          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              style={{ display: Mobile() ? 'block' : 'none' }}
            >
              <MenuIcon />
            </IconButton>

            {/* The outside of the drawer */}
            <Drawer
              anchor="right"
              open={opened}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <Box
                sx={{
                  p: 2,
                  height: 1,
                }}
              >
                <Button sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </Button>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ mb: 2 }}>
                  <ListItemButton>
                    <Link to="/mentor-application">
                      <Button
                        id="basic-button"
                        onClick={handleClick}
                        style={{ color: 'black' }}
                      >
                        Mentor Application
                      </Button>{' '}
                    </Link>
                  </ListItemButton>

                  <ListItemButton>
                    <Link to="/mentee-application">
                      <Button
                        id="basic-button"
                        onClick={handleClick}
                        style={{ color: 'black' }}
                      >
                        Mentee Application
                      </Button>
                    </Link>
                  </ListItemButton>

                  <ListItemButton>
                    <Button
                      id="basic-button"
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onMouseOver={handleClick}
                      onClick={handleClick}
                      style={{ color: 'black' }}
                    >
                      Tools
                      <KeyboardArrowDownIcon />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        onMouseLeave: handleClose,
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <a
                          href={firstMeetingTool}
                          download="MSHI Mentorship-First Meeting Tool"
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          First Meeting Tool
                        </a>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <a
                          href={meetingTool}
                          download="MSHI Mentorship-Meeting Tool"
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          Meeting Tool
                        </a>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <a
                          href={questionnaire}
                          download="Mentor and Mentee Questionnaire"
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          Mentor & Mentee Questionnaire
                        </a>
                      </MenuItem>
                    </Menu>
                  </ListItemButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    bottom: '0',
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                  }}
                >
                  <Link to="/login">
                    <Button variant="outlined" sx={{ m: 1, width: 0.5 }}>
                      Login
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </div>
      </Toolbar>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};
