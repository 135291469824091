import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ActionBar from './components/ActionBar';
import SelectableList from './components/SelectableList';
import ApplicationServices from '../../api/services/applicationServices';
import ActionSign from './components/ActionSign';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 'bold',
  padding: theme.spacing(1),
  margin: theme.spacing(1),
}));

export default function MentorMenteeMatching() {
  const [mentor, setMentor] = useState([]);
  const [mentee, setMentee] = useState([]);
  const [mentorSelected, setMentorSelected] = useState(
    'Please select a Mentor from the list'
  );
  const [menteeSelected, setMenteeSelected] = useState(
    'Please select a Mentee from the list'
  );
  const [mentorSelectedId, setMentorSelectedId] = useState(null);
  const [menteeSelectedId, setMenteeSelectedId] = useState(null);

  const dataPrepare = async (correspondingType, correspondingId) => {
    if (correspondingType === 'Mentor') {
      const newMentor = await ApplicationServices.getMentorsByMenteeId(
        correspondingId
      );
      setMentor(newMentor);
    }
    if (correspondingType === 'Mentee') {
      const newMentee = await ApplicationServices.getMenteesByMentorId(
        correspondingId
      );
      setMentee(newMentee);
    }
  };

  useEffect(async () => {
    await dataPrepare('Mentor', -1);
    await dataPrepare('Mentee', -1);
  }, []);

  const refresh = async () => {
    await dataPrepare('Mentor', menteeSelectedId);
    await dataPrepare('Mentee', mentorSelectedId);
  };

  const handleSelection = async (selectionModel, type) => {
    let selectedName = 'Please select a ' + type + ' from the list';
    let userId = -1;
    let correspondingType;
    let list = [];
    if (type === 'Mentor') {
      list = mentor;
      correspondingType = 'Mentee';
    }
    if (type === 'Mentee') {
      list = mentee;
      correspondingType = 'Mentor';
    }
    if (selectionModel.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      userId = selectionModel[0];
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].user_account_id === userId) {
          selectedName = list[i].first_name + '' + list[i].last_name;
          if (type === 'Mentor') {
            setMentorSelected(selectedName);
            setMentorSelectedId(userId);
          }
          if (type === 'Mentee') {
            setMenteeSelected(selectedName);
            setMenteeSelectedId(userId);
          }
          break;
        }
      }
    } else {
      if (type === 'Mentor') {
        setMentorSelected(selectedName);
        setMentorSelectedId(null);
      }
      if (type === 'Mentee') {
        setMenteeSelected(selectedName);
        setMenteeSelectedId(null);
      }
    }
    await dataPrepare(correspondingType, userId);
  };

  const columns = [
    {
      field: 'selectStatus',
      headerName: 'Paired',
      width: 60,
      renderCell: ActionSign,
    },
    {
      field: 'Name',
      headerName: 'Name',
      width: 150,
      valueGetter: (params) =>
        `${params.row.first_name || ''} ${params.row.last_name || ''}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
  ];

  return (
    <Grid container spacing={2} direction="row" justifyContent="center">
      <Grid item xs={4} justifyContent="center">
        <Typography variant="h5">Mentor</Typography>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4} justifyContent="center">
        <Typography variant="h5">Mentee</Typography>
      </Grid>
      <Grid item xs={4}>
        <Item>{mentorSelected}</Item>
      </Grid>
      <Grid item xs={4}>
        <ActionBar
          mentor={mentor}
          mentee={mentee}
          mentorId={mentorSelectedId}
          menteeId={menteeSelectedId}
          refresh={refresh}
        />
      </Grid>
      <Grid item xs={4}>
        <Item>{menteeSelected}</Item>
      </Grid>
      <Grid item xs={6}>
        <SelectableList
          columns={columns}
          rows={mentor}
          rowId="user_account_id"
          type="Mentor"
          handleSelection={handleSelection}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectableList
          columns={columns}
          rows={mentee}
          rowId="user_account_id"
          type="Mentee"
          handleSelection={handleSelection}
        />
      </Grid>
    </Grid>
  );
}
