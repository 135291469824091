import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

function Mbutton({ statusName, statusValue, sourceValue, ...options }) {
  return (
    <Button
      color={statusValue === sourceValue ? 'success' : 'secondary'}
      variant="contained"
      value={statusValue}
      sx={{ flexGrow: 1 }}
      // sx={{ border: '0.5px solid black', mr: 0.5 }}
      {...options}
    >
      {statusName}
    </Button>
  );
}

Mbutton.propTypes = {
  statusName: PropTypes.string.isRequired,
  statusValue: PropTypes.string.isRequired,
  sourceValue: PropTypes.string.isRequired,
};

export default Mbutton;
