import React, { useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Box,
  FormLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import icon from '../../components/NavBar/favicon.ico';
import applicationServices from '../../api/services/applicationServices';
import ThankYou from './thankyou';
import { Tracks } from '../../ENV';
import MeetPreference from './MeetPreference';

const schemaValidator = z.object({
  first_name: z.string().nonempty(`First Name is required`),
  last_name: z.string().nonempty(`Last Name is required`),
  email: z.string().nonempty(`Email is required`).email('Email is not valid'),
  phone: z
    .string()
    .transform((value) =>
      /^\d{10}$/.test(value)
        ? `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`
        : value
    )
    .refine((value) => /\d{3}-\d{3}-\d{4}/.test(value), {
      message: 'Please follow the format 123-123-1234',
    })
    .optional()
    .or(z.literal('')),
  track: z.string(),
  experience: z.string(),
  career_accomplishments: z.string(),
  skills: z.string(),
  interests: z.string(),
});

export default function MentorApplication() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schemaValidator),
  });

  const [meetPreference, setMeetPreference] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const style = {
    surveyTitle: {
      width: '20 %',
      paddingRight: '100px',
      paddingTop: '200px',
    },
    survey: {
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  const onSubmitHandler = async (data) => {
    const result = {
      ...data,
      meet_preference: meetPreference,
      invite_type: 'application',
      status: 'pending',
    };
    // console.log('submitted', result);
    try {
      await applicationServices.addMentorApplication(result);
      setOpenDialog(true);
    } catch (e) {
      toast.error('Application submit failed: ' + e);
    }
  };

  return (
    <Box
      component="main"
      maxWidth={800}
      display="flex"
      paddingTop="120px"
      margin="auto"
    >
      <Box paddingRight="100px">
        <img alt="" src={icon} width="60" height="60" />{' '}
        <h3 className={style.surveyTitle}>Mentor Application</h3>
      </Box>
      <Box className={style.survey}>
        <form
          onSubmit={handleSubmit(onSubmitHandler, () => {
            console.log(errors);
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('first_name')}
                autoComplete="given-name"
                variant="outlined"
                fullWidth
                label="*First Name"
                autoFocus
                error={!!errors.first_name}
                helperText={errors.first_name && errors.first_name.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('last_name')}
                variant="outlined"
                fullWidth
                label="*Last Name"
                name="last_name"
                autoComplete="family-name"
                error={!!errors.last_name}
                helperText={errors.last_name && errors.last_name.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('email')}
                variant="outlined"
                fullWidth
                type="email"
                label="*Email Address"
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('phone')}
                variant="outlined"
                fullWidth
                label="Phone"
                autoComplete="phone"
                error={!!errors.phone}
                helperText={
                  errors.phone ? errors.phone.message : 'Example: 123-123-1234'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="track-label">Track</InputLabel>
                <Select
                  {...register('track')}
                  labelId="track-label"
                  id="track"
                  label="Track"
                  defaultValue=""
                >
                  {Object.entries(Tracks).map(([key, value]) => (
                    <MenuItem value={key} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <MeetPreference
                meetPreference={meetPreference}
                setMeetPreference={setMeetPreference}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">
                Have you ever participated in a formal mentoring program?
              </FormLabel>
              <TextField
                {...register('experience')}
                label="Mentoring Experience"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('career_accomplishments')}
                label="Career Accomplishments"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">
                What skills currently used in your career/job?{' '}
              </FormLabel>
              <TextField
                {...register('skills')}
                label="Professional Skills"
                multiline
                rows={4}
                fullWidth
              />
              <FormHelperText>
                i.e. programming language, analytics, cybersecurity, management
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('interests')}
                label="Hobbies or Interests"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*  Your Website*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}.
          </Typography>
        </Box>
      </Box>
      <ThankYou isOpen={openDialog} />
    </Box>
  );
}
